import React from 'react';
// import { hydrate, render } from "react-dom";
import ReactDOM from 'react-dom/client';
import App from './App';

import "./assets/css/animate.css";
import "./assets/fonts/avenir-next/avenir-next-font.css";
import "./assets/fonts/brandon-grotesque/brandon-grotesque-font.css";
import "./assets/css/reset.css";
import "./assets/css/main-alpha.css";
import "./assets/css/main-charlie.css";
import "./assets/css/media-alpha.css";
import 'react-notifications/lib/notifications.css';


const rootElement = document.getElementById("root");


// if (rootElement.hasChildNodes()) {
// 	const root = ReactDOM.hydrateRoot(rootElement, <App/>)
// 	//root.render(<App />);
// } else {
// 	const root = ReactDOM.createRoot(rootElement);
//   	root.render(<App />);
// }
const root = ReactDOM.createRoot(rootElement);
root.render(
	<App />
);
import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes.js";
import { ParallaxProvider } from 'react-scroll-parallax';
import { HelmetProvider } from "react-helmet-async";

function App() {
	return (
		<HelmetProvider>
			<div className="App">
				<ParallaxProvider>
					<RouterProvider router={router} />
				</ParallaxProvider>

				<img src={require("./assets/img/home-first-screen-bg.jpg")} width="2500" height="1668" alt="Hero Banner image" style={{ display: "none" }} className="preload-me" />
				<img src={require("./assets/img/hazelnut-pile.png")} width="134" height="80" alt="Hazelnuts" style={{ display: "none" }} className="preload-me"/>
				<img src={require("./assets/img/products-first-section-bg.jpg")} alt="Product" style={{ display: "none" }} className="preload-me"/>
				<img src={require("./assets/img/products-section-two-img.jpg")} alt="Products" style={{ display: "none" }} className="preload-me"/>
                <img src={require("./assets/img/about-us-first-section-bg.jpg")} alt="About us" style={{ display: "none" }} className="preload-me"/>
                <img src={require("./assets/img/requests-samples-first-section-bg.jpg")} alt="Request Samples" style={{ display: "none" }} className="preload-me"/>
                <img src={require("./assets/img/contact-us-section-one.jpg")} alt="Contact us" style={{ display: "none" }} className="preload-me"/>
			</div>
		</HelmetProvider>
	);
}

export default App;

import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import I18n from "../lang"

const Footer = () => {
    const { lang } = useParams();
    return (
        <>
            <footer className="footer">
                <nav className="footer-nav">
                    <ul>
                        <li><NavLink to={`${lang ? '/'+ lang : ''}/products`}>{ I18n('nav_products') }</NavLink></li>
                        <li><NavLink to={`${lang ? '/'+ lang : ''}/request-samples`}>{ I18n('nav_request') }</NavLink></li>
                        <li><NavLink to={`${lang ? '/'+ lang : ''}/about-us`}>{ I18n('nav_about') }</NavLink></li>
                        <li><NavLink to={`${lang ? '/'+ lang : ''}/contact-us`}>{ I18n('nav_button') }</NavLink></li>                       
                        <li><NavLink to= {`${lang ? '/'+ lang : ''}/privacy-policy`}>{ I18n('nav_privacy') }</NavLink></li>
                        <li><NavLink to= {`${lang ? '/'+ lang : ''}/cookie-policy`}>{ I18n('nav_cookie') }</NavLink></li>
                    </ul>
                </nav>
                <div className="footer-copyr">{ I18n('footer_copy') }</div>
            </footer>
        </>
    )
}

export default Footer;

import React, { useEffect } from 'react';


// English - En
// German - De
// French - Fr
// Spanish - Es
// Polish - Pl

import * as Choices from 'choices.js';

const SelectBox = ({ value, change }) => {

    const init = () => {
        const choiceLanguages = document.querySelector('.js-choice-languages');

        if(choiceLanguages){
            const choices = new Choices(choiceLanguages, {
                searchChoices: false,
                searchEnabled: false,
                callbackOnCreateTemplates: function(template) {
                    return {
                    item: ({ classNames }, data) => {
                        return template(`
                        <div
                            class="
                                ${classNames.item} 
                                ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable } 
                                ${ data.placeholder ? classNames.placeholder : ''}
                            "
                            data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}
                        >
                            ${data.value}
                        </div>
                        `);
                    },
                    
                    };
                },
            });
        }
    }

    const selectedChange = (e) =>{
        if(change){
            change(e.target.value)
        }
    }

    useEffect(()=>{
        init();
    }, []);
    
    return (
        <select className="js-choice-languages" onChange={selectedChange} value={value}>
            <option value="en">English</option>
            <option value="de">German</option>
            <option value="fr">French</option>
            <option value="es">Spanish</option>
            <option value="pl">Polish</option>
        </select>
    )
}

export default SelectBox;
